export const INTRO_INSTALL = [
  {
    header: "Welcome to the Lilli system",
    img: "/assets/intro/lilli_kit_box_no_bkgd.png",
    video: "",
    alt: "A photo of the Lilli Kit with all of the sensors inside",
    text: "The Lilli system requires all sensors to be installed to work correctly.",
  },
  {
    header: "Is the data sent securely?",
    img: "/assets/intro/hub_power_ethernet_cables.png",
    video: "",
    alt: "A photo of the Lilli Hub with power and ethernet cables",
    text: "An encrypted SIM card or ethenet cable securely transmits the data to Lilli for monitoring by way of communication between the sensors to the hub.",
  },
  {
    header: "Three motion sensors",
    img: "/assets/intro/motion_sensors.png",
    video: "",
    alt: "A video of all three motion sensors, showing each of their labels: M1, M2, and M3",
    text: "3 motion sensors use infrared light to track movement in the hallway, bathroom and bedroom whilst maintaining privacy.",
  },
  {
    header: "How to know the motion sensor is working?",
    img: "/assets/intro/motion_sensors.png",
    video: "",
    alt: "A motion sensor flashing green when a hand is waved in front of it",
    text: "The green light will flash green when you wave your hand in front of it.",
  },
  {
    header: "Two door sensors",
    img: "/assets/intro/door_sensors.png",
    video: "",
    alt: "A video of the two door sensors, showing each of their labels: D1 and D2",
    text: "2 door sensors monitor when the front door and fridge are opened",
  },
  {
    header: "How to know the door sensor is working?",
    img: "/assets/intro/motion_sensors.png",
    video: "",
    alt: "A video of the door sensor flashing green when the magnet is pulled away from the sensor.",
    text: "The door sensor will also flash green when the sensor is separated from the magnet.",
  },
  {
    header: "One smart plug",
    img: "/assets/intro/smartplug_online.png",
    video: "",
    alt: "An image of the smart plug online, with the LED green light glowing",
    text: "1 smart plug monitors eating & drinking habits via kettle, microwave, or toaster.",
  },
  {
    header: "How to know if the smart plug is working?",
    img: "/assets/intro/smartplug_online.png",
    video: "",
    alt: "A video of the smart plug LED going from dark to green when the LED button is pressed",
    text: "The LED light on the side of the smart plug should be green, indicating electricity can flow through to the connected appliance. The LED button should turn green when pressed.",
  },
  {
    header: "Each sensor is labelled",
    img: "",
    video: "",
    alt: "",
    text: "Do not just put the sensors anywhere! Each sensor has a unique label, i.e. M1, M2, M3 - corresponding to the location they belong to.",
  },
  {
    header: "Temperature",
    img: "",
    video: "",
    alt: "",
    text: "All the sensors measure temperature.",
  },
];
