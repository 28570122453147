import React, { useState } from "react";
import theme from "../../../Styles/theme.scss";
import styles from "./styles.module.scss";
import { useParams, useNavigate } from "react-router-dom";
// API
import { postHubProperty } from "@intelligentlilli/api-layer";
// State
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  setRefreshOrganisationHubs,
  setRefreshUser,
} from "../../../State/slices/session-slice";
// Components
import CTAButton from "../../../Components/CTAButton";
import InstallationCard from "../../../Components/InstallationCard";
import InstallationGoBackButton from "../../../Components/InstallationGoBackButton";
import InstallationSplash from "../../../Components/InstallationSplash";
import { useViewport } from "../../../Components/ViewportProvider";
// services
import { mobileBreakpoint } from "../../../Services/config";
// hooks
import { useGetUserRole, useNativeInstallAuth } from "../../../Services/Hooks";
// icons
import {
  ThumbsUpIcon,
  ThumbsDownIcon,
} from "../../../Styles/Icons/DesignSystem";

const InstallFinish = ({ isWeb, baseURL, goBackLink }) => {
  const { id, devicestring } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // sizing
  const { width } = useViewport();
  const isDesktop = width > mobileBreakpoint;

  const { userIsJustAnInstaller, userIsManagerOrAdmin, userIsFriendOrFamily } =
    useGetUserRole();
  console.log("userIsManagerOrAdmin?", userIsManagerOrAdmin);
  console.log("userIsJustAnInstaller?", userIsJustAnInstaller);

  const server = useSelector((state) => state.session.server);
  const sensorLocations = useSelector((state) => state.sensorLocations);
  // Local state
  const [installNotes, setNotes] = useState();
  // back-up check that all postHubProperty requests were sent
  const [sentSensorLocations, setSentSensorLocations] = useState(false);
  const [sentInstallationNotes, setSentInstallationNotes] = useState(false);
  const [sentInstallationComplete, setSentInstallationComplete] =
    useState(false);
  const [stage, setStage] = useState("start");

  const allPostRequestsSuccessful =
    sentSensorLocations &&
    (installNotes ? sentInstallationNotes : true) &&
    sentInstallationComplete;

  const goToSensorsScreen = () => {
    navigate(`${baseURL}/sensors/${id}/${devicestring}`);
  };

  // Set cookie headeres for native install
  useNativeInstallAuth({ path: "install/native/finish" });

  const completeInstallation = () => {
    dispatch(setLoading(true));
    // if the user refreshses on this screen, it will clear the sensor locations
    // when that happens, this will result in the 'Uh oh' Screen where the 'Try Again' button will
    // navigate the user back to the sensors screen

    console.log("sensorLocations", sensorLocations);
    if (!sensorLocations) {
      console.log("no sensor locations!");
      setSentSensorLocations(false);
      setSentInstallationNotes(false);
      setSentInstallationComplete(false);
      setStage("end");
      dispatch(setLoading(false));
      return;
    }

    postHubProperty(
      server,
      id,
      "SensorLocations",
      JSON.stringify(sensorLocations),
      "web"
    )
      .then((res) => {
        console.log("1- <Sensor locations> Res.status:", res.status);
        console.log("1- <Sensor locations> Res.ok?:", res.ok);
        setSentSensorLocations(res.ok);
        if (!res.ok) {
          console.log("1- Res NOT ok");
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        console.log("1- Sensor locations: there has been an error:", err);
        dispatch(setLoading(false));
      });

    console.log("Installation <Notes>:", installNotes);
    if (installNotes) {
      postHubProperty(server, id, "InstallationNotes", installNotes, "web")
        .then((res) => {
          console.log("2- Installation <Notes> Res.status:", res.status);
          console.log("2- Installation <Notes> Res.ok?:", res.ok);
          setSentInstallationNotes(res.ok);
          if (!res.ok) {
            console.log("2- Res NOT ok");
            dispatch(setLoading(false));
          }
        })
        .catch((err) => {
          console.log("2- Installation <Notes>: there has been an error:", err);
          dispatch(setLoading(false));
        });
    }

    postHubProperty(server, id, "InstallationStatus", "complete", "web")
      .then((res) => {
        console.log("3- Installation <Status> Res.status:", res.status);
        console.log("3- Installation <Status> Res.ok?:", res.ok);
        setStage("end");
        dispatch(setLoading(false));
        setSentInstallationComplete(res.ok);
        if (res.ok) {
          // IMPORTANT! Do not remove or amend this console log as the native app depends on it to refresh users
          console.log("Signal - Native Install Complete");
          dispatch(setRefreshUser(true)); // necessary for all user roles
          if (userIsManagerOrAdmin) {
            dispatch(setRefreshOrganisationHubs(true));
          }
        }
      })
      .catch((err) => {
        console.log("3- Installation <Status>: there has been an error:", err);
        dispatch(setLoading(false));
        setSentInstallationComplete(false);
      });
  };

  return (
    <>
      <InstallationCard style={styles.card}>
        <div className={styles.inner_card}>
          {stage === "start" && (
            <>
              <div data-private className={styles.top}>
                <div className={styles.instructions}>
                  <h2 className={styles.main_title}>Any notes?</h2>
                  <p>
                    Almost there! Use the space below to add any notes you would
                    like about this install.
                  </p>
                </div>
                <label>
                  Install notes
                  <textarea
                    value={installNotes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="e.g. Service user stays at family home on Thursdays..."
                    className={styles.text_area}
                  />
                </label>
              </div>
              <div className={styles.buttons}>
                <div>
                  <InstallationGoBackButton
                    textColor={theme.primary6}
                    onClick={() => navigate(-1)}
                    label={"Back to sensors"}
                  />
                </div>
                <div>
                  <CTAButton
                    style={{ width: "100%" }}
                    onClick={completeInstallation}
                  >
                    Complete
                  </CTAButton>
                </div>
              </div>
            </>
          )}
          {stage === "end" && allPostRequestsSuccessful && (
            <InstallationSplash
              icon={<ThumbsUpIcon color={theme.expected2} />}
              headers={["Your Lilli kit is installed and fully active!"]}
              message={[
                "The sensors are online and now monitoring activity and safety. You should be able to test each sensor and see their data within a few seconds.",
                "It only takes a few days to learn the household's pattern of behaviour. Once that is understood, you will be able to see if any activity or behaviour is outside of the expected range.",
              ]}
              isDesktop={isDesktop}
              nextButtonLabel={
                isWeb
                  ? userIsFriendOrFamily
                    ? "You're all done - download the app!"
                    : userIsJustAnInstaller
                      ? "Start another install"
                      : "View Lilli data"
                  : null // this is for native "Finish" // finish is for native
              }
              nextButtonOnClick={
                isWeb
                  ? userIsFriendOrFamily
                    ? () => navigate("/download-app")
                    : userIsJustAnInstaller
                      ? () => navigate("/install")
                      : () =>
                          navigate(
                            `/lilli-users/${id}?tab=event%20log&view=day`
                          )
                  : null // this is for native  --> console.log("Native Install Success: Go To Settings")
              }
              doNotFlexContainer={true}
            />
          )}
          {stage === "end" && !allPostRequestsSuccessful && (
            <InstallationSplash
              icon={<ThumbsDownIcon color={theme.risk3} />}
              headers={["Install failed"]}
              message={[
                "Let's try that again, but if the problem continues, please contact ",
              ]}
              email={"support@intelligentlilli.com"}
              nextButtonLabel={"Try again"}
              nextButtonOnClick={goToSensorsScreen}
              isDesktop={isDesktop}
              goBackOnClick={() => navigate(goBackLink)}
              goBackButtonLabel={"Exit install"}
              doNotFlexContainer={true}
            />
          )}
        </div>
      </InstallationCard>
    </>
  );
};

export default InstallFinish;
