import React from "react";
import styles from "./styles.module.scss"; // Unified styles import
import { useNavigate } from "react-router-dom";
import { mobileBreakpoint } from "../../../Services/config";
// Components
import InstallationCard from "../../../Components/InstallationCard";
import InstallationNextButton from "../../../Components/InstallationNextButton";
import Carousel from "../../../Components/Carousel";
import { useViewport } from "../../../Components/ViewportProvider";
// Constants
import { INTRO_INSTALL } from "../../../Services/Constants/Installation/introInstall";
// Hooks
import { useNativeInstallAuth } from "../../../Services/Hooks";

const InstallIntro = () => {
  const navigate = useNavigate();
  const { width } = useViewport();
  const isDesktop = width > mobileBreakpoint;

  // Set cookie headeres for native install
  const { isNative } = useNativeInstallAuth({
    path: "install/native",
  });

  // Correctly handle native and web navigation routes
  const baseRoute = isNative ? "/install/native" : "/install";

  return (
    <InstallationCard>
      <div className={styles.card_content}>
        <Carousel items={INTRO_INSTALL} />

        <div className={styles.buttons}>
          <InstallationNextButton
            isDesktop={isDesktop}
            onClick={() => navigate(`${baseRoute}/select`)}
            disabled={false}
            label={"Next"}
          />
        </div>
      </div>
    </InstallationCard>
  );
};

export default InstallIntro;
