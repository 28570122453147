import React, { useEffect, useState, useRef } from "react";

const VideoPlayer = ({ src }) => {
  const [videoError, setVideoError] = useState(false);
  const videoRef = useRef();

  useEffect(() => {
    videoRef.current?.load();
  }, [src]);

  return (
    <div>
      {!videoError ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "left",
            height: "300px",
          }}
        >
          <video
            height="100%"
            controls
            onError={() => setVideoError(true)}
            ref={videoRef}
            disablePictureInPicture
            loop
            muted
            autoPlay
            controlsList="nodownload nofullscreen noremoteplayback"
            playsInline
            type="video/mp4"
            style={{ borderRadius: "8px" }}
          >
            <source src={src} />
          </video>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "left",
            height: "250px",
            objectFit: "contain",
          }}
        >
          {" "}
          <img
            src="/images/installation/deviceId.png"
            alt="QR code sticker with 16 digits"
          />{" "}
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
