import React from "react";
import styles from "./styles.module.scss";
// Components
import InstallationNextButton from "../InstallationNextButton";
import InstallationGoBackButton from "../InstallationGoBackButton";
import PropTypes from "prop-types";

const InstallationSplash = ({
  isDesktop,
  icon,
  headers,
  message,
  email,
  goBackOnClick,
  goBackButtonLabel,
  nextButtonOnClick,
  nextButtonLabel,
  cypresstestid,
  troubleshooting,
  doNotFlexContainer,
}) => {
  return (
    <div
      className={
        doNotFlexContainer
          ? styles.do_not_flex_container
          : styles.splash_container
      }
      cypresstestid={cypresstestid}
    >
      <div className={styles.splash_top}>
        <div className={styles.icon}>{icon}</div>
        {headers.map((header, i) => (
          <div className={styles.installation_header} key={i} data-private>
            {header}
          </div>
        ))}
        {troubleshooting?.length > 0 &&
          troubleshooting.map((step) => (
            <div className={styles.installation_message}>{step}</div>
          ))}
        {message && (
          <div className={styles.installation_message}>
            {message.map((msg, index) => (
              <div key={index} className={styles.message_item}>
                {msg}
              </div>
            ))}
            {email && (
              <span>
                <a
                  href={`mailto: ${email}`}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.email}
                >
                  {email}
                </a>
              </span>
            )}
          </div>
        )}
      </div>
      <div className={styles.splash_bottom}>
        {goBackButtonLabel && (
          <InstallationGoBackButton
            onClick={goBackOnClick}
            isDesktop={isDesktop}
            label={goBackButtonLabel}
          />
        )}
        {nextButtonLabel && (
          <InstallationNextButton
            onClick={nextButtonOnClick}
            label={nextButtonLabel}
          />
        )}
      </div>
    </div>
  );
};

InstallationSplash.propTypes = {
  isDesktop: PropTypes.bool,
  icon: PropTypes.node,
  headers: PropTypes.array,
  message: PropTypes.array,
  email: PropTypes.string,
  installSUCard: PropTypes.node,
  installNotes: PropTypes.node,
  goBackOnClick: PropTypes.func,
  goBackButtonLabel: PropTypes.string,
  nextButtonOnClick: PropTypes.func,
  nextButtonLabel: PropTypes.string,
  cypresstestid: PropTypes.string,
};

export default InstallationSplash;
