export const refreshUser = (
  server,
  dispatch,
  setUser,
  setLoading,
  setServiceUsers,
  getCheckAuthStateAPI,
  origin
) => {
  dispatch(setLoading(true));
  getCheckAuthStateAPI(server, origin)
    .then((res) => {
      console.log("is refreshing user?");
      if (res.ok) {
        console.log("refresh user hubs succcessful");
        dispatch(setUser(res.body)); // Persisting the user to state
        console.log("res.body", res.body);
        // If user isn't a manager then the hubs array is the list of SUs that the rest of the app will deal with. Persisting it in state. (managers get to see all organisation SUs)
        if (!res?.body?.roles?.includes("manager")) {
          dispatch(setServiceUsers(res.body.hubs));
        }
      }
      dispatch(setLoading(false));
    })
    .catch((err) => {
      console.log(err);
      alert("Failed to refresh user");
      dispatch(setLoading(false));
    });
};
