import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";

const Carousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const videoRef = useRef();

  useEffect(() => {
    videoRef.current?.load();
  }, [currentIndex]);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const goToPrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + items.length) % items.length
    );
  };

  const renderDots = () => {
    return (
      <div className={styles.dotsContainer}>
        {items.map((_, index) => (
          <div
            key={index}
            className={
              index === currentIndex
                ? `${styles.dot} ${styles.active}`
                : styles.dot
            }
            onClick={() => setCurrentIndex(index)}
          ></div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.carouselWrapper}>
      <h2 className={styles.introHeader}>{items[currentIndex].header}</h2>
      <div className={styles.carouselContainer}>
        <button className={styles.carouselButton} onClick={goToPrev}>
          &#x3c;
        </button>
        <div className={styles.contentContainer}>
          <div className={styles.fixedContainer}>
            {items[currentIndex].img && (
              <img
                src={items[currentIndex].img}
                alt={items[currentIndex].alt || "Carousel Item"}
                className={styles.image}
              />
            )}
            {items[currentIndex].video && (
              <video
                className={styles.video}
                controls
                ref={videoRef}
                disablePictureInPicture
                loop
                muted
                autoPlay
                controlsList="nodownload nofullscreen noremoteplayback"
                playsInline
              >
                <source src={items[currentIndex].video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
          {items[currentIndex].text && (
            <div className={styles.text}>{items[currentIndex].text}</div>
          )}
        </div>

        <button className={styles.carouselButton} onClick={goToNext}>
          &#x3e;
        </button>

        {renderDots()}
      </div>
    </div>
  );
};

export default Carousel;
